import state from './modulePromoRatePlanManagementState.js'
import mutations from './modulePromoRatePlanManagementMutations.js'
import actions from './modulePromoRatePlanManagementActions.js'
import getters from './modulePromoRatePlanManagementGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

